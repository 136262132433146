@import "./variables";
// -------------------------
// --- You can add your custom CSS here and it will overwrite template styles.
// -------------------------
// .site-header,
// .mobile-header__panel {
//     background: white;
//     color: black;
// }

.search--location--mobile-header {
    .search__button {
        fill: $secondary-color;
    }
}

.btn-dark.custom {
    background-color: #3d464d !important;
}

.mobile-header__panel {
    // border-bottom: 1px solid $accent-opposite-color;
    box-shadow: 0 1.5px 3px rgba(0, 0, 0, 0.5);
}

.nav-panel__indicators {
    background: transparent;
    width: 75.5%;
    justify-content: flex-end !important;

    padding: 5px;
    border-radius: 5px;
    // margin-top: 5px;
    // padding-top: 0px;
    height: 42px;
}

.search--location--header .search__input {
    border: 1px solid #3d464d;
    border-right: none;
}

.search__button {
    background: $gradient-color !important;
    border: 1px solid #3d464d !important;
}

.search--location--header .search__suggestions {
    border-bottom: 1px solid #3d464d;
    border-right: 1px solid #3d464d;
    border-left: 1px solid #3d464d;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    max-height: 500px;
    position: absolute;
    overflow-x: hidden;
    top:40px !important;
}

.btn-primary,
.btn-primary:hover {
    background-color: $accent-color;
    border-color: $accent-color;
    color: white;
    fill: white;
}

.btn-secondary.custome {
    background-color: #ffd333;
    border-color: #ffd333;
    color: white;
    fill: white;
}

.product__meta {
    a {
        color: $accent-color;
        font-weight: 300;
    }
    a:hover {
        color: $accent-color;
    }
}

.account-nav__item--active a {
    color: $accent-color;
}

a:hover {
    color: $accent-color;
}

.totop__button {
    color: $secondary-color;
}

@media (min-width: 768px) {
    .block-features__list {
        flex-wrap: wrap;
    }
    .block-features__item {
        flex-basis: auto;
        width: calc(50% - 1px);
        flex-shrink: 0;
    }
    .block-features__divider:nth-child(4n) {
        width: 100%;
        height: 2px;
    }
}

.img-profile__store {
    right: 15px;
    top: -15%;
    width: 100px;
    height: 100px;
    border: 2px solid #f0f0f0;
}

@media (min-width: 768px) {
    .img-profile__store {
        right: 35px;
        top: -36%;
        width: 150px;
        height: 150px;
    }
}

.nav-negotiation {
    // height: 58px;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: 1px;
    // border-bottom: 1px solid rgb(245, 245, 245);
    // position: sticky;
    top: 56px;
    background: white;
    -webkit-overflow-scrolling: touch;

    .nav-content-negotiation {
        // overflow: scroll auto;
        white-space: nowrap;
        // margin: 0px -3px;
        padding: 12px 0px;
        scroll-behavior: smooth;

        .nav-item-negotiation.active {
            border: 1px solid #0e336d !important;
            color: #0e336d !important;
        }
        .nav-item-negotiation.danger {
            border: 1px solid #dd242e;
            color: #dd242e;
        }
        .nav-item-negotiation {
            display: inline-flex;
            -moz-box-align: center;
            align-items: center;
            -moz-box-pack: center;
            justify-content: center;
            border: 1px solid rgb(245, 245, 245);
            height: 32px;
            font-size: 14px;
            color: rgb(58, 58, 58);
            font-weight: 600;
            padding: 0px 8px;
            border-radius: 4px;
            margin: 0px 3px;
            cursor: pointer;
        }
    }
}

.dropdown-item.custom:hover {
    text-decoration: none !important;
}

.btn-secondary.custom.focus,
.btn-secondary.custom,
.btn-secondary.custom:focus,
.btn-secondary.custom:active,
.btn-secondary.custom:hover {
    background: transparent !important;
    color: black !important;
    fill: black !important;
}

.block-header__title.custom {
    position: relative;
    overflow: hidden;
    height: 100%;
}
.block-header__title.custom::after {
    content: "Lihat Selengkapnya";
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 29px;
    background-color: white;
    transition: 0.4s;
}

.block-header__title.custom:hover::after {
    top: 0;
}

.img-contain {
    width: 100%;
    height: 70px !important;
    object-fit: contain;
}

.block-features__img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.table td.border-top-none {
    border-top: none !important;
}

// checkbox

.custom-input[type="checkbox"] {
    display: none;
}

.custom-label-check,
.custom-label-cancel {
    position: relative;
}

.custom-label-check::before {
    content: "";
    background: url("img/check-circle.svg");
    background-position: center;
    background-size: contain;
    width: 32px;
    height: 32px;
    position: absolute;
    left: -44px;
    top: -8px;

    transform: scale(0) rotateZ(180deg);
    transition: all 0.4s cubic-bezier(0.54, 0.01, 0, 1.49);
}

.custom-input[type="checkbox"]:checked + label::before {
    transform: scale(1) rotateZ(0deg);
}

.custom-label-check::after {
    content: "";
    border: 2px solid #27ae60;
    width: 28px;
    height: 28px;
    position: absolute;
    left: -42px;
    top: -6px;
    border-radius: 50%;
}

.custom-label-cancel::after {
    content: "";
    // border: 2px solid red;
    width: 24px;
    height: 24px;
    position: absolute;
    left: -42px;
    top: -6px;
    border-radius: 50%;
}

.custom-label-cancel::before {
    content: "";
    background: url("img/x-circle.svg");
    background-position: center;
    background-size: contain;
    width: 32px;
    height: 32px;
    position: absolute;
    left: -44px;
    top: -8px;

    transform: scale(0) rotateZ(180deg);
    transition: all 0.4s cubic-bezier(0.54, 0.01, 0, 1.49);
}

.vertical-timeline.vertical-timeline-custom-line::before {
    background: #424242;
}

.vertical-timeline.vertical-timeline-custom-line
    .vertical-timeline-element--work
    .vertical-timeline-element-icon {
    box-shadow: 0 0 0 4px #1976d2, inset 0 2px 0 rgba(0, 0, 0, 0.08),
        0 3px 0 4px rgba(0, 0, 0, 0.05);
}

.vertical-timeline.vertical-timeline-custom-line
    .vertical-timeline-element--education
    .vertical-timeline-element-icon {
    box-shadow: 0 0 0 4px #c2185b, inset 0 2px 0 rgba(0, 0, 0, 0.08),
        0 3px 0 4px rgba(0, 0, 0, 0.05);
}

.chat-search-box {
    -webkit-border-radius: 3px 0 0 0;
    -moz-border-radius: 3px 0 0 0;
    border-radius: 3px 0 0 0;
    padding: 0.75rem 1rem;
    .input-group {
        .form-control {
            -webkit-border-radius: 2px 0 0 2px;
            -moz-border-radius: 2px 0 0 2px;
            border-radius: 2px 0 0 2px;
            border-right: 0;
            &:focus {
                border-right: 0;
            }
        }
        .input-group-btn {
            .btn {
                -webkit-border-radius: 0 2px 2px 0;
                -moz-border-radius: 0 2px 2px 0;
                border-radius: 0 2px 2px 0;
                margin: 0;
                i {
                    font-size: 1.2rem;
                    line-height: 100%;
                    vertical-align: middle;
                }
            }
        }
    }
}
.users-container {
    position: relative;
    padding: 1rem 0;
    border-right: 1px solid #e6ecf3;
    height: 100%;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
}
.users {
    padding: 0;
    .person {
        position: relative;
        width: 100%;
        padding: 10px 1rem;
        cursor: pointer;
        border-bottom: 1px solid #f0f4f8;
        &:hover {
            background-color: #ffffff;
            background-image: -webkit-grad
                ient(linear, left top, left bottom, from(#e9eff5), to(#ffffff));
            background-image: -webkit-linear-gradient(
                to left,
                #e9eff5,
                #ffffff
            );
            background-image: -moz-linear-gradient(to left, #e9eff5, #ffffff);
            background-image: -ms-linear-gradient(to left, #e9eff5, #ffffff);
            background-image: -o-linear-gradient(to left, #e9eff5, #ffffff);
            background-image: linear-gradient(to left, #e9eff5, #ffffff);
        }
        &:last-child {
            border-bottom: 0;
        }
        .user {
            display: inline-block;
            position: relative;
            margin-right: 10px;
            img {
                width: 48px;
                height: 48px;
                -webkit-border-radius: 50px;
                -moz-border-radius: 50px;
                border-radius: 50px;
            }
            .status {
                width: 10px;
                height: 10px;
                -webkit-border-radius: 100px;
                -moz-border-radius: 100px;
                border-radius: 100px;
                background: #e6ecf3;
                position: absolute;
                top: 0;
                right: 0;
            }
            .status.online {
                background: #9ec94a;
            }
            .status.offline {
                background: #c4d2e2;
            }
            .status.away {
                background: #f9be52;
            }
            .status.busy {
                background: #fd7274;
            }
        }
        p.name-time {
            font-weight: 600;
            font-size: 0.85rem;
            display: inline-block;
            .time {
                font-weight: 400;
                font-size: 0.7rem;
                text-align: right;
                color: #8796af;
            }
        }
    }
    .person.active-user {
        background-color: #ffffff;
        background-image: -webkit-gradient(
            linear,
            left top,
            left bottom,
            from(#f7f9fb),
            to(#ffffff)
        );
        background-image: -webkit-linear-gradient(to left, #f7f9fb, #ffffff);
        background-image: -moz-linear-gradient(to left, #f7f9fb, #ffffff);
        background-image: -ms-linear-gradient(to left, #f7f9fb, #ffffff);
        background-image: -o-linear-gradient(to left, #f7f9fb, #ffffff);
        background-image: linear-gradient(to left, #f7f9fb, #ffffff);
    }
}
.selected-user {
    width: 100%;
    padding: 0 15px;
    min-height: 64px;
    line-height: 64px;
    border-bottom: 1px solid #e6ecf3;
    -webkit-border-radius: 0 3px 0 0;
    -moz-border-radius: 0 3px 0 0;
    border-radius: 0 3px 0 0;
    span {
        line-height: 100%;
    }
    span.name {
        font-weight: 700;
    }
}
.chat-container {
    position: relative;
    padding: 1rem;
    li.chat-left {
        display: flex;
        flex: 1;
        // float: left;
        width: 80%;
        flex-direction: row;
        margin-bottom: 40px;
    }
    li.chat-right {
        display: flex;
        flex: 1;
        flex-direction: row;
        float: right;
        width: 80%;
        margin-bottom: 40px;
        justify-content: flex-end;
        > .chat-avatar {
            margin-left: 20px;
            margin-right: 0;
        }
        > .chat-text {
            text-align: right;
            &:before {
                right: -20px;
                border-color: transparent transparent transparent #ffffff;
                left: inherit;
            }
        }
        > .chat-hour {
            margin: 0 15px 0 0;
        }
    }
    li {
        img {
            width: 48px;
            height: 48px;
            -webkit-border-radius: 30px;
            -moz-border-radius: 30px;
            border-radius: 30px;
        }
        .chat-avatar {
            margin-right: 20px;
        }
        .chat-name {
            font-size: 1rem;
            color: #999999;
            text-align: center;
        }
        .chat-text {
            padding: 0.4rem 1rem;
            -webkit-border-radius: 4px;
            -moz-border-radius: 4px;
            border-radius: 4px;
            background: #ffffff;
            font-weight: 300;
            line-height: 150%;
            position: relative;
            &:before {
                content: "";
                position: absolute;
                width: 0;
                height: 0;
                top: 10px;
                left: -20px;
                border: 10px solid;
                border-color: transparent #ffffff transparent transparent;
            }
        }
        .chat-hour {
            padding: 0;
            margin-bottom: 10px;
            font-size: 0.75rem;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            margin: 0 0 0 15px;
            > span {
                font-size: 16px;
                color: #9ec94a;
            }
        }
    }
}
.chat-form {
    padding: 15px;
    width: 100%;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ffffff;
    border-top: 1px solid white;
}
ul.chat-box {
    list-style-type: none;
    margin: 0;
    padding: 0;
}
.card.chat {
    border: 0;
    // background: #f4f5fb;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    margin-bottom: 2rem;
    box-shadow: none;
}
@media (max-width: 767px) {
    .chat-search-box {
        display: none;
    }
    .users {
        .person {
            .user {
                img {
                    width: 30px;
                    height: 30px;
                }
            }
            p.name-time {
                display: none;
                .time {
                    display: none;
                }
            }
        }
    }
    .chat-container {
        li.chat-left {
            flex-direction: column;
            margin-bottom: 30px;
            .chat-avatar {
                margin: 0 0 5px 0;
                display: flex;
                align-items: center;
            }
            .chat-hour {
                justify-content: flex-end;
            }
            .chat-name {
                margin-left: 5px;
            }
        }
        li.chat-right {
            flex-direction: column;
            margin-bottom: 30px;
            .chat-avatar {
                order: -1;
                margin: 0 0 5px 0;
                align-items: center;
                display: flex;
                justify-content: right;
                flex-direction: row-reverse;
            }
            .chat-hour {
                justify-content: flex-start;
                order: 2;
            }
            .chat-name {
                margin-right: 5px;
            }
        }
        li {
            img {
                width: 32px;
                height: 32px;
            }
            .chat-text {
                font-size: 0.8rem;
            }
        }
    }
}

@media (min-width: 768px) {
    .w-sm-50 {
        width: 50%;
    }
}

.text-line {
    overflow: hidden;
    text-align: center;
    margin: 1.5em 0;
}

.text-line:before,
.text-line:after {
    background-color: #000;
    content: "";
    display: inline-block;
    height: 1px;
    position: relative;
    vertical-align: middle;
    width: 50%;
}

.text-line:before {
    right: 0.5em;
    margin-left: -50%;
}

.text-line:after {
    left: 0.5em;
    margin-right: -50%;
}

.topbar-button {
    height: 28px;
    line-height: 14px;
    font-size: 14px;
    border-radius: 5px;
    color: black;
    border: 0;
    background-color: rgb(238, 238, 239);
    &:hover,
    &:focus,
    &:active {
        height: 28px;
        line-height: 14px;
        font-size: 14px;
        border-radius: 5px;
        color: black !important;
        background-color: rgb(238, 238, 239) !important;
        border: 0 !important;
        padding: 0.375rem 1.25rem;
    }
}

.pagination {
    &.white {
        .page-item {
            .page-link {
                color: white;
            }
        }
    }

    .page-item {
        &.active {
            .page-link {
                background-color: rgb(255, 98, 33);
                color: white;
                &:hover {
                    background-color: rgb(255, 98, 33);
                    color: white;
                }
            }
        }
        
        .page-link {
            color: $accent-color;
            &:hover {
                background-color: rgb(255, 98, 33);
                color: white;
            }
        }
    }
}

.store-info__title {
    font-size: 14px;
    font-weight: 900;
    margin: 2px 0 -3px 0;
    display: block;
}

.dropcart__product-name {
    a:hover {
        color: inherit;
    }
}

.product-card {
    box-shadow: 5px 5px 5px -5px rgba(0, 0, 0, 0.5) !important;
}

.product-card__name {
    text-align: left;
    font-weight: 700;
    a:hover {
        color: inherit;
    }
}

@media (min-width: 768px) {
    .line-height-80px {
        // line-height: 80px;
        font-size: 18px;
        text-align: center;
    }

    .block-slideshow__slide-image.block-slideshow__slide-image--desktop {
        display: block;
        border: 1px solid black;
        border-radius: 20px;
    }

    // .block-slideshow__slide-image--desktop {
    //     display: block;
    // }
    .block-slideshow {
        margin-bottom: 40px;
    }
}

@media (max-width: 768px) {
    .block-slideshow {
        margin-bottom: 0;
    }

    .block-slideshow__slide-image--mobile {
        background-size: cover !important;
    }
    .block-slideshow__slide-image--desktop {
        display: none;
    }
}

.nav-panel__indicators,
.nav-panel__departments {
    box-shadow: none;
}

a.dropdown-item {
    &:hover,
    &:active {
        color: $accent-color !important;
        font-weight: 500;
    }
}

.mobile-header__logo {
    img {
        width: 140px;
        margin-left: 20px;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .mobile-header__logo {
        img {
            width: 80%;
            margin-left: 20px;
        }
    }

    .search__body {
        width: 400px;
        .search__input {
            border: 1px solid #3d464d;
        }
    }
}

@media (max-width: 768px) {
    .view-options__control {
        .site-header__search {
            position: absolute;
            width: 36%;
        }
    }
}

.dropcart__products-list {
    max-height: 50vh !important;
    overflow-y: auto !important;
}

.search-compare,
.search-compare:active,
.search-compare:focus {
    height: 46px;
    width: 300px;
    appearance: none;
    border: 1px solid rgb(245, 245, 245);
    border-radius: 6px;
    font-size: 14px;
    color: rgb(79, 79, 79);
    font-family: "Titillium Web", sans-serif;
    background: rgb(248, 246, 242) none repeat scroll 0% 0%;
}

@media print {
    @page {
        margin: 0;
    }
}

// loader
.full-page-loader {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
}

.full-page-loader > img {
    animation: 1.8s infinite heartbeat;
}

@keyframes heartbeat {
    0% {
        transform: scale(1);
    }
    25% {
        transform: scale(1.05);
    }
    50% {
        transform: scale(1);
    }
    75% {
        transform: scale(1.05);
    }
    100% {
        transform: scale(1);
    }
}

.departments_links--custom {
    // width: 81.989%;
    width: 100%;
    max-height: 80vh;
    height: calc(80vh - 153px);
    z-index: 0;
    opacity: 0;
    background: white;
    // position: fixed;
    position: absolute;
    top: 50px;
    // left: 0;
    // transform: translate(11%, 0);
    transform: translate(0, 1%);
    box-shadow: 0 0 9px -2px rgba(0, 0, 0, 0.5);

    transition: height $local-transition-duration ease-in-out,
        opacity $local-transition-duration ease-in-out;
}

.departments_links_container {
    height: calc(80vh - 153px);
}

.departments_links_list {
    padding: 12px 0;
    border-right: 1px solid #ddd;
    overflow-y: auto;
    display: block;
}
.departments_links_item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 12px;
    height: 40px;
    font-size: 14px;
    cursor: pointer;
    color: #3d464d;
    transition: all 0.3 ease-out;
    &:hover,
    &.active {
        background-color: #dfe3e6;
        color: #3d464d;
    }

    .title {
        width: 100%;
    }
    .icon {
        padding-left: 18px;
        margin-left: auto;
        color: #3d464d;
    }
}

.cp {
    cursor: pointer;
}

.dropnotification__list {
    overflow-y: hidden !important;
    padding: 0 !important;

    // padding: 15px 0 !important;
    // max-height: 100px;
}

.product-card {
    border-radius: 20px !important;
    &::before {
        border-radius: 20px !important;
    }
}

.product-card__quickview {
    border-top-left-radius: 0px !important;
    border-top-right-radius: 20px !important;
    padding-top: 10px !important;
    padding-right: 10px !important;
}

.select__control {
    border-radius: 10px !important;
}
